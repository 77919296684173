import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import WorksTableItem from "@/entity/aftersale/ticket/WorksTableItem";
import WorkView from "@/modules/work-view/work-view.vue";
import WorkSchedule from "@/modules/work-schedule/work-schedule.vue";
import SelectLevel from "@/modules/select-level/select-level.vue";
import ProcessWorkspace from "@/modules/flowable/workspace/process-workspace/process-workspace.vue";
import API from "@/api/aftersale/ticket/works";
import BusinessApi from "@/api/aftersale/ticket/business";
import InfluenceApi from "@/api/aftersale/ticket/influence";
import StarApi from "@/api/aftersale/ticket/star";
import OrgAPI from "@/api/system/org";
import UserAPI from "@/api/system/user1";
import ProjectAPI from "@/api/project/project-list";
import OrgTypeAPI from "@/api/system/master/org-type";

@Options({
    name: "app-aftersale-ticket-works",
    components: {
        "app-work-view": WorkView,
        "app-work-schedule": WorkSchedule,
        "app-select-level": SelectLevel,
        ProcessWorkspace,
    },
})
export default class Works extends BaseTableMixins<WorksTableItem> implements Partial<BaseTableInterface<WorksTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new WorksTableItem();
        this.editModalItem = new WorksTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            userCode: {
                type: "eq",
                name: "u.user_code",
                title: "发起人",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.userCodeLevelModalOpen,
            },
            orgCode: {
                type: "router",
                name: "o.org_code",
                title: "所属组织",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.orgCodeLevelModalOpen,
            },
            projectId: {
                type: "eq",
                name: "p.project_id",
                title: "所属项目",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.projectIdLevelModalOpen,
            },
            businessId: {
                type: "router",
                name: "w.business_id",
                title: "业务类型",
                parent: -1,
                value: null,
                openFn: this.businessIdLevelModalOpen,
            },
            influenceId: {
                type: "router",
                name: "w.influence_id",
                title: "影响范围",
                parent: -1,
                value: null,
                openFn: this.influenceIdLevelModalOpen,
            },
            orgTypeId: {
                type: "eq",
                name: "t.org_type_id",
                title: "组织类型",
                parent: null,
                value: null,
                openFn: this.orgTypeIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //OrgAPI
    get getOrgApi(): any {
        return OrgAPI;
    }

    //UserAPI
    get getUserApi(): any {
        return UserAPI;
    }

    //ProjectAPI
    get getProjectApi(): any {
        return ProjectAPI;
    }

    //组织类型API
    get getOrgTypeAPI(): any {
        return OrgTypeAPI;
    }

    get getBusinessApi() {
        return BusinessApi;
    }

    get getInfluenceApi() {
        return InfluenceApi;
    }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new WorksTableItem();
        this.editModalItem = new WorksTableItem();
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.projectId)) return "所属项目不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.businessId)) return "业务类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.influenceId)) return "影响范围不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.workSubject)) return "工单主题不能为空！";
        return null;
    }

    public openWorkViewModal(item: WorksTableItem) {
        this.editModalItem = item;
        (this.$refs.workViewModal as any).openModal();
    }

    public openWorkScheduleModal(item: WorksTableItem) {
        this.editModalItem = item;
        (this.$refs.workScheduleModal as any).openModal();
    }

    public modRoleAuth(rolePath: string, infoData: any): boolean {
        if (!this.$tools.isEmpty(infoData.completeName)) return false;
        if (!this.$tools.isEmpty(infoData.completeTime)) return false;
        const userCode = this.authUser.userCode;
        //责任人修改权限
        if (userCode === infoData.ownerProject) return true;
        if (userCode === infoData.ownerMain) return true;
        if (userCode === infoData.ownerInfo) return true;
        if (userCode === infoData.ownerIntegrated) return true;
        return this.Auth(rolePath);
    }

    //userCode搜索字段
    private userCodeLevelSearchField: any = [
        { field: "o.org_name", name: "组织名称" },
        { field: "o.org_remark", name: "组织说明" },
        { field: "u.user_code", name: "工号" },
        { field: "u.user_name", name: "用户名" },
        { field: "u.real_name", name: "姓名" },
    ];
    //userCode层级选择器打开
    public userCodeLevelModalOpen() {
        (this.$refs.userCodeLevel as any).openModal();
    }
    //userCode层级选择器提交
    public async userCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().userCode : item;
        this.tableRequestItem.lockItem.userCode.value = curItem.value;
        this.tableRequestItem.lockItem.userCode.title = curItem.title;
        this.tableRequestItem.lockItem.userCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //orgCode搜索字段
    private orgCodeLevelSearchField: any = [
        { field: "org_code", name: "代码" },
        { field: "org_name", name: "名称" },
        { field: "org_remark", name: "说明" },
    ];
    //orgCode层级选择器打开
    public orgCodeLevelModalOpen() {
        (this.$refs.orgCodeLevel as any).openModal();
    }
    //orgCode层级选择器提交
    public async orgCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgCode : item;
        this.tableRequestItem.lockItem.orgCode.value = curItem.value;
        this.tableRequestItem.lockItem.orgCode.title = curItem.title;
        this.tableRequestItem.lockItem.orgCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //orgTypeId搜索字段
    private orgTypeIdLevelSearchField: any = [
        { field: "t.org_type_name", name: "名称" },
        { field: "t.org_type_remark", name: "说明" },
    ];
    //orgTypeId层级选择器打开
    public orgTypeIdLevelModalOpen() {
        (this.$refs.orgTypeIdLevel as any).openModal();
    }
    //orgTypeId层级选择器提交
    public async orgTypeIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgTypeId : item;
        this.tableRequestItem.lockItem.orgTypeId.value = curItem.value;
        this.tableRequestItem.lockItem.orgTypeId.title = curItem.title;
        this.tableRequestItem.lockItem.orgTypeId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //projectId搜索字段
    private projectIdLevelSearchField: any = [
        { field: "project_name", name: "名称" },
        { field: "project_remark", name: "说明" },
    ];
    //projectId层级选择器打开
    public projectIdLevelModalOpen() {
        (this.$refs.projectIdLevel as any).openModal();
    }
    //projectId层级选择器提交
    public async projectIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().projectId : item;
        this.tableRequestItem.lockItem.projectId.value = curItem.value;
        this.tableRequestItem.lockItem.projectId.title = curItem.title;
        this.tableRequestItem.lockItem.projectId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //influenceId搜索字段
    private influenceIdLevelSearchField: any = [
        { field: "influence_name", name: "名称" },
        { field: "influence_remark", name: "说明" },
    ];
    //influenceId层级选择器打开
    public influenceIdLevelModalOpen() {
        (this.$refs.influenceIdLevel as any).openModal();
    }
    //influenceId层级选择器提交
    public async influenceIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().influenceId : item;
        this.tableRequestItem.lockItem.influenceId.value = curItem.value;
        this.tableRequestItem.lockItem.influenceId.title = curItem.title;
        this.tableRequestItem.lockItem.influenceId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //businessId搜索字段
    private businessIdLevelSearchField: any = [
        { field: "business_name", name: "名称" },
        { field: "business_remark", name: "说明" },
    ];
    //businessId层级选择器打开
    public businessIdLevelModalOpen() {
        (this.$refs.businessIdLevel as any).openModal();
    }
    //businessId层级选择器提交
    public async businessIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().businessId : item;
        this.tableRequestItem.lockItem.businessId.value = curItem.value;
        this.tableRequestItem.lockItem.businessId.title = curItem.title;
        this.tableRequestItem.lockItem.businessId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    // <app-button-tooltip v-if="Auth('/aftersale/ticket/works/workData')" content="查看工单" @btnClick="openWorkViewModal(item)">
    // <svg-icon name="check" />
    // </app-button-tooltip>
    // <app-button-tooltip v-if="modRoleAuth('/aftersale/ticket/works/modSchedule', item)" content="修改进度" @btnClick="openWorkScheduleModal(item)">
    // <svg-icon name="rate-of-progress" />
    // </app-button-tooltip>

    //处理流程
    public flowProcessing(item: any) {
        item.isProcess = false;
        (this.$refs.processView as any).show(item);
    }

    //流程提交
    public onFlowSubmit(e: any) {
        if ("RefreshTable" === e) this.getList(0);
    }

    //更新工单星级
    public uploadWorkStar() {
        const businessId = this.editModalItem.businessId;
        const influenceId = this.editModalItem.influenceId;
        if (!this.$tools.isEmpty(businessId) && !this.$tools.isEmpty(influenceId)) {
            StarApi.getStar({ businessId, influenceId }).then((res: any) => {
                this.editModalItem.workStar = res.data.workStar;
            });
        }
    }

    get projectIdSelectApi() {
        return {
            api: (query: any) => ProjectAPI.idSelect(query),
            parent: "org_code",
            status: "project_status",
            name: "project_name",
            id: "project_id",
            cid: this.editModalItem.projectId,
        };
    }

    get businessIdSelectApi() {
        return {
            api: (query: any) => BusinessApi.idSelect(query),
            parent: "business_parent_id",
            status: "business_status",
            name: "business_name",
            id: "business_id",
            cid: this.editModalItem.businessId,
        };
    }

    get influenceIdSelectApi() {
        return {
            api: (query: any) => InfluenceApi.idSelect(query),
            parent: "influence_parent_id",
            status: "influence_status",
            name: "influence_name",
            id: "influence_id",
            cid: this.editModalItem.influenceId,
        };
    }
}
