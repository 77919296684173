import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/aftersale/ticket/star",
    // 获取star
    getStar(query: any) {
        return requests.post(this.modulesUrl + "/getStar", query);
    },
};
